/* Header Start */

.elementor-element {
  --widgets-spacing: 20px;
}

.elementor-element {
  --flex-direction: initial;
  --flex-wrap: initial;
  --justify-content: initial;
  --align-items: initial;
  --align-content: initial;
  --gap: initial;
  --flex-basis: initial;
  --flex-grow: initial;
  --flex-shrink: initial;
  --order: initial;
  --align-self: initial;
  flex-basis: var(--flex-basis);
  flex-grow: var(--flex-grow);
  flex-shrink: var(--flex-shrink);
  order: var(--order);
  align-self: var(--align-self);
}

.elementor-column {
  position: relative;
  min-height: 1px;
  display: flex;
}

.elementor-element, .elementor-lightbox {
  --swiper-theme-color: #000;
  --swiper-navigation-size: 44px;
  --swiper-pagination-bullet-size: 6px;
  --swiper-pagination-bullet-horizontal-gap: 6px;
}

@media (min-width: 768px){
  .elementor-column.elementor-col-100, .elementor-column[data-col="100"] {
    width: 100%;
  }

  .elementor-column.elementor-col-33, .elementor-column[data-col="33"] {
    width: 33.333%;
  }

  .elementor-928 .elementor-element.elementor-element-1355460 {
    width: 35.11%;
  }
}

.elementor-widget-wrap {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
  display: flex;
}

.elementor-928 .elementor-element.elementor-element-ea293cc {
  margin-top: 0%;
  margin-bottom: 0%;
}

.elementor-section .elementor-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

@media (max-width: 1024px){
  .elementor-section .elementor-container {
      flex-wrap: wrap;
  }

  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1024px;
  }
}

.elementor-section.elementor-section-boxed>.elementor-container {
  max-width: 1140px;
}

.elementor-section.elementor-section-items-bottom>.elementor-container {
  align-items: flex-end;
}

.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1200px;
}

.elementor-928 .elementor-element.elementor-element-752cd09 > .elementor-container {
  min-height: 200px;
}

.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
  padding: 10px;
}

.elementor-928 .elementor-element.elementor-element-1355460 > .elementor-element-populated {
  margin: 0px 5px 5px 10px;
  --e-column-margin-right: 5px;
  --e-column-margin-left: 10px;
  padding: 0px 0px 0px 0px;
}

.elementor-element .elementor-widget-container {
  transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform var(--e-transform-transition-duration,.4s);
}

.elementor a {
  box-shadow: none;
  text-decoration: none;
}

.elementor-widget-image a {
  display: inline-block;
}

.elementor img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.elementor-widget-image img {
  vertical-align: middle;
  display: inline-block;
}

.elementor-widget-image {
  text-align: center;
}

.elementor-928 .elementor-element.elementor-element-bee6b08 img {
  max-width: 66%;
}

@media (max-width: 767px){
  .elementor-928 .elementor-element.elementor-element-bee6b08 img {
      width: 71%;
  }
  .elementor-column {
    width: 100%;
  }
}


/* Header End */

.step-item-icon{
  display: flex;
  align-items: center;
}

.step-item-text{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 151% */

  letter-spacing: 1px;
  text-transform: uppercase;

  color: #454545;
}

.step-item:not(:last-child) .step-item-icon::after{
  content: '';
  width: 6rem;
  border: 1.25987px solid #BFBFBF;;
}

.step-item-icon-img{
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 73.49px;
  height: 73.49px;
  background: #EAEAEA;
}

.step-item.active .step-item-icon-img{
  background: #F85431;
}

.step-item-icon img{
  width: 44px;
}


/* Ticket Types Card */

.rf-card-header{
  background: #2D2D2D;
  border-radius: 9.19315px 9.19315px 0px 0px;
  padding: 1rem 1.5rem;
}

.rf-card-header .card-header-title,
.rf-card-header .card-header-info{
  font-family: 'Poppins';
  font-style: normal;

  line-height: 28px;
  /* identical to box height, or 116% */

  letter-spacing: 0.919315px;
  text-transform: uppercase;

  color: #FFFFFF;

}

.rf-card-header .card-header-title{
  font-weight: 500;
  font-size: 23.9022px;
}

.rf-card-header .card-header-info{
  font-weight: 400;
  font-size: 16.5477px;
}

.rf-card-body {
  background: #F4F4F4;
  border-radius: 0px;
}

.ticket-types-item{
  border-bottom: 0.919315px solid #C8C8C8;
}

.tti-title,.tti-price,.tti-desc{
  font-family: 'Poppins';
  font-style: normal;
}

.tti-title{
  font-weight: 600;
  font-size: 23.9022px;
  line-height: 28px;
  letter-spacing: 0.919315px;
  text-transform: capitalize;
  color: #121212;
}

.tti-price{

  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.919315px;
  text-transform: capitalize;
  color: #686868;
  }
.tti-desc{
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 1px;
  color: #121212;
}

.ttq-quantity-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.ttq-plus, .ttq-minus,.ttq-input{
  font-family: 'Poppins';
  font-style: normal;
}

/* Chrome, Safari, Edge, Opera */
.ttq-input::-webkit-outer-spin-button,
.ttq-input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
.ttq-input[type=number] {
-moz-appearance: textfield;
}


.ttq-input{
  font-weight: 500;
  font-size: 27.28px;
  line-height: 34px;
  letter-spacing: 1.13995px;
  text-transform: capitalize;
  color: #343434;

  text-align: center;
  width: 3rem;
  border: none;
  background: none;
  outline: none;
  appearance: none;
}

.ttq-plus, .ttq-minus{
  display: inline-block;
  width: 31px;
  height: 28.52px;
  background: #2D2D2D;
  border-radius: 4.89292px;
  cursor: pointer;

  font-weight: 400;
  font-size: 21.08px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.978585px;
  text-transform: capitalize;
  
  color: #FFFFFF;
}

/* footer */

.rf-card-footer{
  border: 0;
  border-radius: 0px;
  background: #F4F4F4;
}

.rf-actions{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.rf-actions-back, .rf-actions-next{
  display: flex;
  align-items: center;
  
  border-radius: 6px;
  padding: 0.35rem 1rem;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 23.9022px;
  line-height: 28px;
  /* identical to box height, or 116% */

  letter-spacing: 0.919315px;
  text-transform: capitalize;
}

.rf-actions-back{
  background: #C4C4C4;
  color: #2D2D2D;
}

.rf-actions-next{
  background: #F85431;
  color: #FFFFFF;
  margin-left: 2rem;
}

.rf-actions-back i{
  margin-right: 0.7rem;
}

.rf-actions-next i{
  margin-left: 0.7rem;
  transform: rotate(-180deg);
}

/* Tickets Card */

.tickets-card-header{
  background: #9d0404;
  border-radius: 10px 10px 0px 0px;
  padding: 1.1rem 1.5rem;
  text-align: center;
}

.tickets-card-header-title{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  /* identical to box height, or 116% */

  letter-spacing: 1px;
  text-transform: capitalize;

  color: #FFFFFF;
}

.tickets-card-body{
  border: 1px solid #E2E2E2;
  filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.2));
  border-radius: 0px;
}

.tickets-card-body-heading{
  text-align: center;
  border-bottom: 0.919315px solid #A4A3A3;
  margin: 0 -1rem;
  padding-bottom: 1rem;
}

.tickets-card-body-heading span{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 151% */

  letter-spacing: 1px;
  text-transform: uppercase;

  color: #2D2D2D;
}

.tickets-card-item{
  display: flex;
  justify-content: space-between;
  border-bottom: 0.919315px solid #B9B7B7;
  padding: 0.7rem;
}

.tickets-card-item-price{
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;
}

.tickets-cart-item-opener{
  background: #F85431;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

.tickets-cart-item-opener img{
  width: 20px;
}

.tickets-card-item span{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 151% */

  letter-spacing: 1px;
  text-transform: capitalize;

  color: #2D2D2D;
}

.tickets-card-item-price span{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 151% */

  letter-spacing: 1px;
  text-transform: capitalize;

  color: #2D2D2D;
}

.tickets-card-item-btn button,
.tickets-card-item-btn a, 
.payment-btn button{
  width: 100%;
  background: #9d0404;
  border: none;
  border-radius: 0px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  /* identical to box height, or 121% */

  letter-spacing: 1px;
  text-transform: capitalize;

  color: #FFFFFF;

}

/* Deals */

.deal-items{
  text-align: center;
}

.deal-item-inner{
  background: #EEEEEE;
  border-radius: 10.367px;
}

.deal-item-icon{
  width: 114px;
  margin: auto;
}
.deal-item-icon img{
  width: 100%;
}

.deal-item-title span{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  /* or 106% */

  text-align: center;
  letter-spacing: 0.14em;

  color: #1E1E1E;
}
.deal-item-price span{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  /* or 106% */

  text-align: center;
  letter-spacing: 0.14em;

  color: #1E1E1E;
}

.deal-item-btn button{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20.8172px;
  line-height: 28px;
  /* identical to box height, or 136% */

  text-align: center;
  letter-spacing: 0.939792px;
  text-transform: capitalize;

  color: #FFFFFF;
  background: #2F2F2F;
  border-radius: 6.13364px;

}

/* Shop */

.shop-item{
  background: #EEEEEE;
  border-radius: 5.52538px;
}

.shop-item-img{
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
  text-align: center;
  max-height: 50vw;
  overflow: hidden;
  font-size: 0;
  background-color: #fff;
}
.shop-item-img img{
  max-width: 940px;
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
}
.shop-item-img-background{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  max-width: 1080px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-filter: blur(50px) brightness(.9);
  filter: blur(50px) brightness(.9);
  z-index: 0;

}

.si-title span,.si-price  span,.si-desc span{
  font-family: 'Poppins';
  font-style: normal;
}

.si-title span{

  font-weight: 700;
  font-size: 17.6255px;
  color: #1E1E1E;
}

.si-price span{
  font-weight: 600;
  font-size: 15.9469px;
  color: #141414;

}

.si-desc span{
  font-weight: 300;
  font-size: 11.7504px;

  letter-spacing: 0.839311px;

  /* Black */

  color: #121212;
}

.si-btn button{
  background: #2F2F2F;
  border-radius: 3.95675px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 13.429px;
  
  letter-spacing: 0.606251px;
  text-transform: capitalize;

  color: #FFFFFF;

}

@media (max-width: 768px){
  .step-item-icon-img{
      width: 48.49px;
      height: 48.49px;
  }
  .step-item-icon img {
      width: 25px;
  }
  .step-item-text{
      font-size: 12px;
      line-height: 8px;
  }
  .step-item:not(:last-child) .step-item-icon::after{
      width: 3rem;
  }

  .rf-card-header .card-header-title {
      font-size: 15.9022px;
  }
  .rf-card-header .card-header-info {
      font-size: 14.5477px;
  }
}


/* Sticky Cart */

.sticky-totals{
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

@media (max-width: 768px) {
  .sticky-totals{
      display: block;
  }
}